/* Inquiry */
.modal-90w-2 {
    z-index: 1002;
    min-width: 60% !important;
    margin: auto;
    margin-top: 2%;
}

.chat-container {
    max-height: 60vh;
    height: 55vh;
    overflow-y: scroll;
    border-radius: 5px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    display: inline-block;
}

/* CSS talk bubble */
.talk-bubble {
    display: inline-block;
    position: relative;
    width: auto;
    max-width: 75%;
    height: auto;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
}

.tb-me {
    margin-right: 1.5em;
    background-color: lightgreen;
    float: right;
}

.tb-img {
    margin-top: 16px;
    width: 53px;
    height: 53px;
    color: grey;
    border-radius: 5px;
}

.tb-him {
    margin-left: 1.5em;
    background-color: lightgrey;
    float: left;
}

.tb-him-first:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -20px;
    right: auto;
    top: 0px;
    bottom: auto;
    border: 22px solid;
    border-color: lightgrey transparent transparent transparent;
}

.tb-me-first:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;

    left: auto;
    right: -20px;
    top: 0px;
    bottom: auto;
    border: 20px solid;
    border-color: lightgreen transparent transparent transparent;
}

/* talk bubble contents */
.talktext {
    padding: 0.5em;
    text-align: left;
    line-height: 1.5em;
    position: relative;
    padding-right: 2.8em;
    white-space: pre-line !important;
}

.talktext-me {
    padding: 0.5em;
    padding-bottom: 0;
    padding-right: 4em;
    text-align: left;
    line-height: 1.5em;
    position: relative;
    white-space: pre-line !important;

}

.talktext p {
    /* remove webkit p margins */
    z-index: 100;
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    color: black;
}

.fileURL {
    z-index: 100;
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
}

.tb-smallContainer {
    position: absolute;
    bottom: 0;
    right: 0;
}

.tb-time {
    font-size: 0.6em;
    padding-right: 1em;
}

.tb-time-me {
    font-size: 0.6em;
    padding-right: 0em;
}

.seenIcon {
    font-size: 1.5em;
    padding-right: 0.1em;
}

.readMore {
    border-radius: 30px;
    background: #800080;
    background: linear-gradient(153deg, #E0B0FF 0%, #ABD9EF 100%);
    color: white;
    width: 40%;
    text-align: center;
    font-weight: bold;
    padding: 0.4em 0;
}

.readMore:hover {
    color: white;
    background: rgb(254, 125, 106);
    background: linear-gradient(153deg, rgba(254, 125, 106, 1) 0%, rgba(252, 148, 175, 1) 100%);
}

.fromTxt {
    color: grey;
    float: left;
    padding-left: 2em;
    margin-top: -0.2em;
    font-size: 0.7em;
}

.sendBtn {
    color: #4a67ff;
}

.sendBtn:hover {
    color: #cc4aff;
    transform: scale(1.2);
}

.attachmentsBox {
    font-weight: bold;
    /* color: grey;
    font-size: 1.2em; */
}

/* .attachmentsBox span:hover {
    color: purple;
} */

.lastOnline-txt {
    font-size: 0.9em;
}

.text-online {
    color: lime;
}

.mine-FloatRight {
    float: right;
}

.mine-FloatLeft {
    float: left;
}

.textbox-img {
    width: 50%;
    padding-bottom: 0.8em;
}

.fileDownload-title {
    font-size: 1.0em !important;
}

.newUnreadMsg {
    border-radius: 30px;
    background: #800080;
    background: linear-gradient(153deg, #E0B0FF 0%, #ABD9EF 100%);
    color: white;
    text-align: center;
    width: 50%;
    float: center;
    height: auto;
    padding: 0vw 1vw;
}

.newUnreadMsg:hover {
    color: white !important;
}

.markAsResolveBtn {
    font-size: 1.2em;
}

.usrInputContainer {
    border-radius: 3px;
}

#usrInput-img {
    width: 15vw;
    height: 15vw;
    padding: 1vw;
    border-radius: 10px;
}

#usrInput-file {
    padding: 1vw;
}

#usrInput-file .fileIcon {
    font-size: 5vw;
    color: grey;
}

.cancelAttachmentBtn {
    color: red;
    margin-top: 0.8vw;
    margin-right: 1vw;
}

.cancelAttachmentBtn:hover {
    color: salmon
}

::-webkit-scrollbar {
    width: 5px
}

::-webkit-scrollbar-track {
    background: #f1f1f1
}

::-webkit-scrollbar-thumb {
    background: #888
}

::-webkit-scrollbar-thumb:hover {
    background: #555
}
/* Preloader */
.section-preloader {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #fff;
  overflow: hidden;
}

.section-preloader.semi {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
}

.section-preloader .preloader {
  position: relative;
  width: 100%;
  height: 100%;
}

.section-preloader .preloader img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  margin: auto;
  animation: preloader-rotation 1.5s infinite linear;
}

@keyframes preloader-rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.modal-90w {
  z-index: 1001;
  max-width: 90% !important;
}

.modal-30w {
  z-index: 1001;
  max-width: 30% !important;
}
.schLogoPreview {
    width: 30vw;
    height: 30vw;
    border-radius: 50% !important;
}

.schCoverPreview {
    width: 45vw;
    height: 30vw;
}

.previewBtn {
    right: 0;
    margin-right: 1rem;
}
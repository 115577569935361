.zoomImgContainer {
    position: fixed;
    top: 20%;
    left: 25%;
    width: 60%;
    height: 60%;
}

.blackBg {
    position: fixed;
    background-color: black;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.closeBtn {
    position: fixed;
    top: 1vw;
    right: 1vw;
    z-index: 10000;
    font-size: 3vw;
}

.zoomImg-caption {
    font-size: 1.5vw;
    color: white;
}
.footer-container{
    padding: .5vw .1vw 0vw .1vw;
    color: black;
    margin-top: .5vw;
    margin-bottom: .5vw;
    font-size: 1.2vw;
}

.footer-container div span {
    font-weight: 500;
    cursor: pointer;
}

.footer-container div:nth-child(1) {
    float: left;
}

.footer-container div:nth-child(2) {
    float: right;
}
@keyframes navBarChg {
  50% {
    margin-left: 9.5%;
  }
}
.headerContainer {
  color: white;
  width: 100%;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.476);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 1.5vw;
  margin-bottom: 1vw;
  min-height: 8vh;
  z-index:1000;
}
.headerContent {
  padding: 0.5vw 1.2vw;
}
.headerIcon {
  font-size: 2vw;
  color: white;
  padding-top: 0.5vw;
}
#navBarIcon {
  padding-right: 1.5vw;
  display: none;
}
.path {
  color: rgba(255, 255, 255, 0.884);
  font-size: 1.2vw;
  margin-bottom: -0.5vw;
}
.pathIcon {
  margin-bottom: 0.2vw;
}
.curTitle {
  padding-right: 3vw;
  padding-top: 0.5vw;
  font-weight: 600;
  font-size: 2vw;
  width: 100%;
}
.headerBottom {
  width: 100%;
  display: flex;
}
.headerRightIcons {
  float: right;
  width: 100%;
  text-align: right;
}
.headerRightIcons .headerIcon:nth-last-child() {
  padding-right: 0;
}
.headerRightIcons .headerIcon {
  padding: 0 0.5vw;
}
.wlcMsg {
  color: rgba(255, 255, 255, 0.884);
}

@media only screen and (max-width: 700px) {
  .headerContent {
    padding: 1.5vw 2.2vw;
  }
  .headerContainer {
    animation: navBarChg 1s linear;
    width: 100%;
    margin-left: 0%;
  }
  #navBarIcon {
    display: block;
  }
  .headerIcon {
    padding-top: 1.4vw;
    font-size: 5vw;
  }
  .path {
    font-size: 3.5vw;
  }
  .headerRightIcons .headerIcon {
    padding: 0 2vw;
  }
  .curTitle {
    padding-right: 3vw;
    font-size: 5vw;
    padding-top: 0.5vw;
  }
  .wlcMsg {
    display: none;
  }
}

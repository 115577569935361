.main-wrapper {
    height: 60vh;
    width: 100%;
    margin-bottom: 1.5em;
    filter: drop-shadow(-1px 5px 3px #ccc);
}

.info-wrapper {
    margin-top: 15px;
}

.map-details {
    text-align: center;
    font-size: 1.2em;
}

.map-details span {
    font-weight: bold;
}

.search-input {
    font-size: 1.2em;
    width: 100%;
    border: 1px solid grey;
    border-radius: 5px;
}

.pac-container {
    z-index: 100000 !important;
}
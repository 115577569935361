.settings-container {
  padding: 1vw 1.5vw;
  padding-bottom: 2.5vw;
  color: black;
  background-color: white;
  margin-top: 2vw;
  border-radius: 10px;
  margin-bottom: 4vw;
}
.layoutTitle {
  background: linear-gradient(
    153deg,
    rgba(45, 87, 253, 1) 0%,
    rgba(34, 193, 195, 1) 100%
  );
  border-radius: 15px;
  padding: 0.5vw 1vw;
  font-size: 1.7vw;
  margin-top: -2vw;
  color: white;
}
.form-group {
  width: 100%;
}
.form-group tr td {
  width: 50%;
}
.form-group label {
  margin-top: 1.2vw;
  font-size: 1.5vw;
}
.settings-gender {
  width: 12% !important;
}
.settings-input {
  width: 90%;
}
.ta {
  height: 10vw;
  resize: none;
}
.tb {
  width: 80% !important;
}
.nameBox {
  display: flex;
  width: 95%;
}
.buttons {
  margin-top: -1.5vw;
}
.buttons button {
  width: 10vw;
}
.buttons button:nth-child(1) {
  margin-right: 2vw;
}

.navBarContainer {
  position: fixed;
  left: 1vw;
  width: 18%;
  height: 94vh;
  margin-right: 1vw;
  color: black;
  border-radius: 20px;
  z-index: 100;
  transition: 0.5s;
  overflow: hidden;
  top: 1.5vw;
  text-align: center;
  background-color: #303034;
}

.navBarContent {
  padding: 0.5vw;
}

.sideNavMenu {
  padding-left: 2vw;
  color: white;
  text-align: left;
}

.sideNavMenu li {
  padding: 0.3vw 0;
  width: 90%;
}

.sideNavMenu li a {
  display: block;
  padding: 0.4vw 0.5vw;
  font-size: 1vw;
  color: white;
  text-decoration: none;
}

.sideNavMenu li a:hover {
  background-color: grey;
  border-radius: 6px;
}

.sideNavMenu li a span {
  padding-left: 0.6vw;
}

.navBarIcon {
  font-size: 2.2vw;
  color: white;
  padding-right: 0.5vw;
}

.sideNavLogo {
  color: white;
  padding: 1vw 0vw;
  font-weight: bold;
  font-size: 1.2vw;
  width: 94%;
  margin: auto;
  box-shadow: #515158 0px 15px 15px -15px;
}

.sideNavLogo img {
  width: 2.6vw;
  height: 2.6vw;
}

.chosen {
  height: auto;
  border-radius: 5px;
}

div[class*="chosen"] {
  background-color: red important;
  border-radius: 6px;
}
.ft-table {
    table-layout: auto;
    width: 100%;
}

.ft-table td {
    table-layout: auto;
    width: 30%;
    padding-bottom: 10px;
}

.ft-table td:first-child {
    padding-top: 0.375rem;
    vertical-align: text-top
}

.ft-table td>span {
    display: none;
    font-size: calc(6px + 0.5vw);
    position: relative;
    top: -2px
}

.modal-dialog button:disabled {
    cursor: not-allowed;
}

.loginSetting-username-msg {
    position: relative;
    float: left;
    font-size: 13px;
    display: none;
}

.loginSetting-username-input-container {
    width: 90%
}

.loginSetting-username-input-container>input {
    width: 100%
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.loggedInContainer {
  color: white;
  height: 100%;
  width: 100%;
  background-color: rgba(240, 242, 245, 255);
  padding: 1.5vw;
}

.content {
  padding-left: 20%;
  width: 100%;
  background-color: #f0f2f5 !important;
  min-height: calc(100vh - 3vw);
}

/* notify */
.notification-container {
  max-height: 43.5vh !important;
  top: auto !important;
  right: auto !important;
  left: 1.5vw;
  bottom: 3vh;
  overflow-y: hidden !important;
}

/* Content's css */
.contentBody {
  margin-top: 2vw;
}

.contentContainer {
  padding: 1vw 1.5vw 2.5vw 1.5vw;
  color: black;
  background-color: white;
  border-radius: 10px;
  margin-top: 2vw;
  margin-bottom: 4vw;
  min-height: calc(100vh - 16vw);
}

.contentTitle {
  display: flex;
  align-items: center;
  background: linear-gradient(153deg,
      rgba(45, 87, 253, 1) 0%,
      rgba(34, 193, 195, 1) 100%);
  border-radius: 15px;
  padding: 0.5rem 0.5rem;
  font-size: calc(1vw + 15px);
  font-weight: 600;
  margin-top: -2vw;
  color: white;
}

.contentTitle span {
  font-size: calc(1vw + 14px);
  font-weight: 600;
  padding: 0 5px;
}

.container {
  padding: 0px !important;
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  column-gap: 2.5%;
  row-gap: calc(2.5vw + 1vh);
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-decoration: none;
  border-radius: 15px;
  background: linear-gradient(156deg,
      rgb(97, 108, 255) 0%,
      rgba(174, 186, 248, 1) 100%);
  text-align: center;
  align-items: center;
  height: 20vw;
  padding: 1vw;
}

.item:hover {
  transform: scale(1.05);
  color: white;
  background: linear-gradient(156deg, #eebd89 0%, #ee41d7 100%);
}

.itemIcon {
  width: 10vw;
  height: 10vw;
}

.itemName {
  margin-top: 1vw;
  font-size: 2.2vw;
  line-height: 2vw;
}

.row {
  margin-bottom: 2vw;
}

/* Forms */
.my-FormLabel {
  font-size: 1rem !important;
}

.my-CheckBox {
  width: 1rem;
  height: 1rem;
}

.my-BtnGrp {
  text-align: right;
}

/* Campus table */
.my-TableContainer {
  overflow-x: auto;
}

.commonTable {
  table-layout: fixed;
}

.my-BilingualInput {
  width: 90% !important;
}

@media only screen and (max-width: 700px) {

  .container {
    grid-template-columns: 30% 30% 30%;
  }

  .contentTitle span {
    font-size: 5vw;
  }

  .my-FormLabel {
    font-size: 5vw !important;
  }
}